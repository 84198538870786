/* eslint-disable sonarjs/cognitive-complexity */
import _ from 'lodash';
import { useSession } from 'next-auth/react';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import appState, { UserReports } from '../lib/appState';
import fetcher from '../lib/utils/fetcher';
import { ReportSnippetType, UserType } from '../types/global';
import { OrganisationFeatureKeys } from '../types/organisations';
import { User } from '../types/user';
import useFeatures from './useFeatures';
import useSWRWithToasts from './useSWRWithToasts';

const useUser = (fetch: boolean = true) => {
  const { user, organisation } = useRecoilValue(appState);
  const setState = useSetRecoilState(appState);

  const { data: sessionUser } = useSession();

  // if no user then revalidate onMount to prevent blank page
  const {
    data: userRequest,
    isValidating,
    mutate: mutateUser,
  } = useSWRWithToasts<{
    user: User;
  }>(fetch && '/api/user', fetcher, {
    revalidateOnMount: !user?.id,
    revalidateOnFocus: false,
  });

  const { data: reportRequest, isValidating: isValidatingReports } =
    useSWRWithToasts<{ reports: UserReports; total: number }>(
      fetch && '/api/user/reports',
      fetcher,
      {
        revalidateOnMount: !user?.id,
        revalidateOnFocus: false,
      }
    );

  const {
    data: bookmarksRequest,
    isValidating: isValidatingBookmarks,
    mutate: mutateBookmarks,
  } = useSWRWithToasts<ReportSnippetType[]>(
    fetch && '/api/user/bookmarks',
    fetcher,
    {
      revalidateOnMount: !user?.id,
      revalidateOnFocus: false,
    }
  );

  // handling the loading states
  const isLoadingUser = !userRequest && isValidating;
  const isLoadingReports = !reportRequest && isValidatingReports;
  const isLoadingBookmarks = !bookmarksRequest && isValidatingBookmarks;
  const isLoading = isLoadingUser || isLoadingReports || isLoadingBookmarks;

  const is_sso = sessionUser?.user?.is_sso;

  const { features, featureIsHidden, featureIsUpSell, featureIsActive } =
    useFeatures({
      featuresArray: user?.features || [],
    });

  // console.log(features, organisation?.features);
  // console.log(_.isEqual(features, organisation?.features));

  // function to check if user has access to a path
  // it users simple path logic to determine if the path is relevant to a feature
  // then returns a true if the user has no access to that feature
  const userHasNoAccessToPath = (path: string) => {
    const routes: [(path?: string) => boolean, OrganisationFeatureKeys][] = [
      // add more features here in the future
      [path => !!path?.includes('/portfolios'), 'PORTFOLIO_MANAGEMENT'],
      [path => !!path?.includes('/portfolios/add'), 'PORTFOLIO_MANAGEMENT'],
      [path => !!path?.includes('/portfolio-reports'), 'PORTFOLIO_REPORTS'],
      [
        path => !!path?.includes('/organisation/integrations/codat'),
        'REPORT_CODAT',
      ],
      [
        path => !!path?.includes('/organisation/integrations/validis'),
        'REPORT_VALIDIS',
      ],
    ];
    const pathRelevantFeature = routes.find(route => route[0](path))?.[1];
    return pathRelevantFeature ? featureIsHidden(pathRelevantFeature) : false;
  };

  React.useEffect(() => {
    setState({
      ...appState,
      organisation,
      user: {
        ...user,
        ...{
          ...userRequest?.data?.user,
          is_sso,
          reports: reportRequest?.data?.reports,
          total: reportRequest?.data?.total,
          bookmarked_reports: bookmarksRequest?.data,
        },
      },
    });
  }, [userRequest, reportRequest, bookmarksRequest]);

  const isAdmin = user?.organisation_role === 'Admin';

  return {
    user: user?.id ? user : (null as User | null),
    mutateBookmarks,
    mutateUser,
    isAdmin,
    features,
    loading: isLoading,
    userHasNoAccessToPath,
    userFeatureIsHidden: featureIsHidden,
    userFeatureIsUpSell: featureIsUpSell,
    userFeatureIsActive: featureIsActive,
    isError: userRequest?.isError,
    error: {
      message: userRequest?.isError ? userRequest?.message : false,
      status: userRequest?.status,
    },
    message: userRequest?.message,
  };
};

export default useUser;
