import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { MenuAlt2Icon } from '@heroicons/react/solid';
import { useTranslations } from 'next-intl';
import React, { Fragment, useRef } from 'react';

import { useMainNavItems } from '../../hooks/useNavigation';
import { usePlaywright } from '../../hooks/usePlaywright';
import { PlaywrightProps } from '../../types/global';
import { NavListProps, NavProps } from '../../types/navigation';
import Button from '../elements/Button';
import Logo from '../elements/Logo';
import WF from '../elements/WFLogo';

const Nav = ({ path, isAdmin, playwrightId }: NavProps) => {
  const [id, setPlaywrightId] = usePlaywright(playwrightId || 'nav');
  const t = useTranslations();
  const { primaryNavigation, secondaryNavigation } = useMainNavItems();

  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const openSidebar = () => setSidebarOpen(true);
  const closeSidebar = () => setSidebarOpen(false);
  const sidebarRef = useRef(null);

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex lg:hidden"
          onClose={setSidebarOpen}
          initialFocus={sidebarRef}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div
              className="relative max-w-xs w-full bg-primary pt-5 pb-4 flex-1 flex flex-col"
              {...id}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={closeSidebar}
                  >
                    <span className="sr-only">{t('close_sidebar')}</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 px-4 flex items-center">
                <div className="max-w-[110px]">
                  {sidebarOpen ? <Logo /> : <WF />}
                </div>
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <NavItems
                  path={path}
                  navigation={primaryNavigation}
                  navStyle={{
                    navStyle: 'px-2 space-y-1',
                    aLink: '',
                    itemIcon: '',
                    itemTitle: '',
                  }}
                  playwrightId={setPlaywrightId('main')}
                />
              </div>
              <div className="flex-shrink-0 flex border-gray-200">
                <NavItems
                  path={path}
                  navigation={secondaryNavigation}
                  isAdmin={isAdmin}
                />
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className="hidden bg-primary lg:flex lg:flex-shrink-0"
        {...setPlaywrightId('desktop')}
      >
        <div className="w-56 flex flex-col">
          <div className="pt-5 pb-4 flex flex-col flex-grow overflow-y-auto">
            <div className="flex-shrink-0 px-4 flex items-center relative">
              <div className="relative w-full h-14">
                <WF classNames="lg:max-w-[110px]  h-[60px] relative mx-auto" />
              </div>
            </div>
            <div className="flex-grow mt-5 flex flex-col">
              <NavItems
                path={path}
                navigation={primaryNavigation}
                playwrightId={setPlaywrightId('desktop')}
              />
            </div>
            <div className="flex-shrink-0 flex border-gray-200">
              <NavItems
                path={path}
                navigation={secondaryNavigation}
                isAdmin={isAdmin}
                playwrightId={setPlaywrightId('desktop')}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Menu for Tablet */}
      <div
        className="hidden bg-primary sm:flex sm:flex-shrink-0 lg:hidden"
        {...setPlaywrightId('tablet')}
      >
        {/* expand menu button */}

        <div className="w-14 flex flex-col">
          <div className="pt-2 pb-4 flex flex-col flex-grow overflow-y-auto">
            <button
              ref={sidebarRef}
              type="button"
              className="z-10 bg-primary p-2 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-highlight"
              onClick={openSidebar}
            >
              <MenuAlt2Icon className="text-bg w-8 h-8" />
            </button>
            <div className="flex-shrink-0 px-3 pt-3 flex items-center relative">
              <div className="relative w-20 h-10">
                <WF />
              </div>
            </div>
            <div className="flex-grow mt-5 flex flex-col">
              <NavItems
                path={path}
                navigation={primaryNavigation}
                noText={true}
                playwrightId={setPlaywrightId('tablet')}
              />
            </div>
            <div className="flex-shrink-0 flex flex-col border-gray-200">
              <NavItems
                path={path}
                navigation={secondaryNavigation}
                noText={true}
                isAdmin={isAdmin}
                playwrightId={setPlaywrightId('tablet')}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Menu for mobile  */}
      <div
        className="absolute w-full md:px-8 xl:px-0 sm:hidden z-30"
        {...setPlaywrightId('mobile')}
      >
        <div className="relative z-10 flex-shrink-0 h-12 bg-primary border-gray-200 flex">
          <button
            type="button"
            className="px-4 text-bg focus:outline-none focus:ring-2 focus:ring-inset focus:ring-highlight md:hidden "
            onClick={openSidebar}
          >
            <span className="sr-only">{t('open_sidebar')}</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="ml-auto mt-2 relative w-[4.25rem] h-8 mr-2 ">
            <WF />
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;

const NavItems = ({
  path,
  navigation,
  noText,
  isAdmin,
  playwrightId,
  keyPrefix = '',
}: NavListProps & PlaywrightProps) => {
  const [id, setPlaywrightId] = usePlaywright(playwrightId || 'nav');
  return (
    <nav className={'flex-1 bg-primary px-2 space-y-1'} {...id}>
      {navigation.map(
        ({
          title,
          name,
          href,
          onClick,
          adminOnly,
          id,
          isIndented,
          ...item
        }) => {
          const key = keyPrefix + id + name;

          if (!isAdmin && adminOnly) return null;
          if (title && !noText) {
            return (
              <div
                key={key}
                className={
                  'text-white rounded-md group py-2 px-2 flex items-center text-sm font-medium'
                }
              >
                {name}
              </div>
            );
          }
          if (title && noText) return <hr key={key} />;

          const isCurrentNav =
            href && path && href !== '/' && path.startsWith(href);

          return (
            <Button
              variant="none"
              key={key}
              linkTo={href}
              onClick={onClick}
              playwrightId={setPlaywrightId(id || '')}
              newClassName={`
              ${
                isCurrentNav || path === href
                  ? 'bg-new-highlight/25 text-white'
                  : 'text-white hover:bg-secondary '
              } group rounded-md py-2 px-2 flex items-center text-sm font-medium relative w-full
                ${isIndented ? "!pl-4 before:content-['•'] before:pr-2" : ''}
            `}
            >
              {item.icon && (
                <item.icon
                  className="text-white flex-shrink-0 h-6 w-6 mr-2"
                  aria-hidden="true"
                />
              )}
              {!noText && name}
            </Button>
          );
        }
      )}
    </nav>
  );
};
