import Head from 'next/head';
import React from 'react';

import siteSettings from '../../lib/settings/site.settings';
import ogImage from '../../public/images/og-image.jpg';

type SEOProps = {
  title?: string;
  description?: string;
  article?: boolean;
  path: string;
};

const Seo = ({ title, description, article, path }: SEOProps) => {
  const metaTitle = title
    ? `${title} | ${siteSettings.title}`
    : siteSettings.title;
  const metaDescription = description || siteSettings.description;
  const metaImage = `${siteSettings.siteUrl}${ogImage}`; // placeholder logo here

  return (
    <Head>
      <noscript>
        Your browser does not support JavaScript! This site works best with
        javascript ( and by best only ).
      </noscript>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />

      {/* Facebook */}
      <meta property="og:url" content={`${siteSettings.siteUrl}/${path}`} />
      <meta property="og:type" content={article ? `article` : `website`} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={siteSettings.siteUrl} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImage} />

      {siteSettings.twitterUsername && (
        <meta name="twitter:creator" content={siteSettings.twitterUsername} />
      )}

      <meta charSet="UTF-8" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png?v=2"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png?v=2"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png?v=2"
      />
      <link rel="manifest" href="/favicon/site.webmanifest?v=2" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg?v=2"
        color="#5bbad5"
      />
      <link rel="shortcut icon" href="/favicon/favicon.ico?v=2" />
      <meta name="msapplication-TileColor" content="#2b5797" />
      <meta
        name="msapplication-config"
        content="/favicon/browserconfig.xml?v=2"
      />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  );
};

export default Seo;
