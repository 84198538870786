import {
  BillingPlanCategory,
  BillingPlanSoftLimits,
} from '../../types/billing';

export const getHardLimits = (
  limits?: { data: BillingPlanSoftLimits } | null
): BillingPlanSoftLimits | null => {
  if (!limits?.data) {
    return null;
  }

  const hardLimits: BillingPlanSoftLimits = Object.keys(limits.data).reduce(
    (acc, key: any) => {
      const _key: BillingPlanCategory = key as BillingPlanCategory;
      acc[_key] = {
        // only total is needed, left remaining and spent for type clarity and ease
        total: limits.data[_key]?.total * 2,
        // don't use below
        remaining: limits.data[_key]?.remaining,
        spent: limits.data[_key]?.spent,
      };
      return acc;
    },
    {} as BillingPlanSoftLimits
  );

  return hardLimits;
};

export const areHardLimitsExceeded = ({
  limits,
  hardLimits,
}: {
  limits?: BillingPlanSoftLimits | null;
  hardLimits?: BillingPlanSoftLimits | null;
}): Record<BillingPlanCategory, boolean> => {
  if (!limits || !hardLimits) {
    return {
      portfolios: false,
      credits: false,
      users: false,
      alerts: false,
    };
  }

  // if hard limit is not set, it is not exceeded
  // necessary if the hard limit is set but set as 0, we
  // shouldn't show the hard limit banner alongside
  // the upsell pages

  return {
    portfolios: hardLimits.portfolios?.total
      ? limits.portfolios.spent >= hardLimits.portfolios.total
      : false,
    credits: hardLimits.credits?.total
      ? limits.credits.spent >= hardLimits.credits.total
      : false,
    users: hardLimits.users?.total
      ? limits.users.spent >= hardLimits.users.total
      : false,
    alerts: hardLimits.alerts?.total
      ? limits.alerts.spent >= hardLimits.alerts.total
      : false,
  };
};

export const areSoftLimitsExceeded = ({
  limits,
}: {
  limits?: BillingPlanSoftLimits | null;
}): Record<BillingPlanCategory, boolean> => {
  if (!limits) {
    return {
      portfolios: false,
      credits: false,
      users: false,
      alerts: false,
    };
  }

  return {
    portfolios: limits.portfolios.spent > limits.portfolios.total,
    credits: limits.credits.spent > limits.credits.total,
    users: limits.users.spent > limits.users.total,
    alerts: limits.alerts.spent > limits.alerts.total,
  };
};

export const upgradeHref =
  'support@wiserfunding.com?Subject=Account Plan Limit Reached';
