interface GetCustomMetricPathsProps {
  data: { name: string; value: string }[];
}

export const getCustomMetricPaths = ({ data }: GetCustomMetricPathsProps) => {
  return (
    data?.reduce(
      (acc: Record<string, string>, { name, value }) => ({
        ...acc,
        [name]: value
      }),
      {}
    ) || {}
  );
};

interface GetCustomMetricProps {
  data: Record<string, any>;
  route: string;
}

export const getCustomMetric = ({ data, route }: GetCustomMetricProps): any => {
  return route
    ?.split('.')
    .reduce(
      (acc, curr) =>
        Array.isArray(acc)
          ? acc?.find((c: any) => c?.name === curr)?.value
          : acc?.[curr],
      data
    );
};

interface SetCustomMetricProps {
  key: string;
  value: number;
  data: Record<string, any> | Record<string, any>[];
}

export const setCustomMetric = ({ key, value, data }: SetCustomMetricProps) => {
  if (Array.isArray(data)) {
    return data.map(item => {
      return {
        ...item,
        [key]: value
      };
    });
  } else if (typeof data === 'object') {
    return {
      ...data,
      [key]: value
    };
  }
};
