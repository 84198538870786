import swr, { KeyedMutator } from 'swr';

import { getHardLimits } from '../lib/utils/billing-helpers';
import fetcher from '../lib/utils/fetcher';
import {
  BillingPlan,
  BillingPlanCategory,
  BillingPlanSoftLimits,
} from '../types/billing';

export interface UseBilling {
  billing: BillingPlan | undefined;
  billingError: any;
  billingLoading: boolean;
  mutateBilling: KeyedMutator<{ data: BillingPlan }>;

  limits: BillingPlanSoftLimits | undefined;
  limitsError: any;
  limitsLoading: boolean;
  mutateSoftLimits: KeyedMutator<{ data: BillingPlanSoftLimits }>;
  hardLimits: BillingPlanSoftLimits | null;
  isLegacy: boolean;
}

const useBilling = ({ orgId }: { orgId?: string }): UseBilling => {
  /**
   * ======================
   * BILLING
   * ======================
   */
  const {
    data: billing,
    error: billingError,
    isLoading: billingLoading,
    mutate: mutateBilling,
  } = swr<{ data: BillingPlan }>(orgId && `/api/billing`, fetcher, {
    revalidateOnFocus: false,
  });

  /**
   * ======================
   * SOFT LIMITS
   * ======================
   */
  const {
    data: limits,
    error: limitsError,
    isLoading: limitsLoading,
    mutate: mutateSoftLimits,
  } = swr<{ data: BillingPlanSoftLimits }>(
    orgId && `/api//billing/limits`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  // hard limits, set at 200% of soft limits

  const hardLimits = getHardLimits(limits);

  return {
    // ----------------------
    // BILLING
    // - for new clients only; legacy clients will not have this data
    // ----------------------
    billing: billing?.data,
    billingError,
    billingLoading,
    mutateBilling,
    // ----------------------
    // limits
    // - for new clients only; legacy clients will not have this data
    // ----------------------
    limits: limits?.data,
    limitsError,
    limitsLoading,
    mutateSoftLimits,
    hardLimits,
    // flag for legacy clients
    isLegacy: !billing?.data && !billingLoading,
  };
};

export default useBilling;
