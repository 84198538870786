/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import React, { FC } from 'react';

import useBilling from '../../hooks/useBilling';
import useOrganisation from '../../hooks/useOrganisation';
import { usePlaywright } from '../../hooks/usePlaywright';
import useSessionStorage from '../../hooks/useSessionStorage';
import {
  areHardLimitsExceeded,
  areSoftLimitsExceeded,
  upgradeHref,
} from '../../lib/utils/billing-helpers';
import { PlaywrightProps } from '../../types/global';
import Cross from '../icons/Cross';
import { MIStat } from '../management/MIStat';

export interface UsageMIProps extends PlaywrightProps {
  maxWidth?: string;
  className?: string;
  usageWrapper?: string;
}

/**
 * This component will show at the top of every page if the user isn't a legacy user.
 * A legacy user is a user that has not been migrated to the new billing system so will
 * not have a `billing_plan` on their organisation.
 *
 * It displays credits (organisation.quota.quota_used) and portfolios (portfolios.total),
 * though the latter will probably change
 */

const MIUsage: React.FC<UsageMIProps> = ({
  maxWidth = 'max-w-5xl',
  playwrightId,
  className = 'sm:absolute sm:top-0',
  usageWrapper,
}) => {
  const t = useTranslations();
  const [id, setPlaywrightId] = usePlaywright(playwrightId || 'usage-mi');
  const { organisation, canUserSeeUsage } = useOrganisation();
  const { limits, hardLimits } = useBilling({ orgId: organisation?.id });
  const isSoftLimitExceeded = areSoftLimitsExceeded({ limits });
  const isHardLimitExceeded = areHardLimitsExceeded({ limits, hardLimits });

  const showSoftLimits = Object.values(isSoftLimitExceeded).some(
    value => value === true
  );

  const showHardLimits = Object.values(isHardLimitExceeded).some(
    value => value === true
  );

  if (!canUserSeeUsage) return null;

  const users = limits?.users;
  const credits = limits?.credits;
  const portfolios = limits?.portfolios;

  const usersRemaining = limits?.users?.remaining ?? 0;
  const portfoliosRemaining = portfolios?.remaining ?? 0;
  const creditsRemaining = credits?.remaining ?? 0;

  const showUserLimits = users && users.total > 0 && isSoftLimitExceeded.users;
  const showPortfolioLimits = portfolios && portfolios.total > 0;

  const content = (
    <>
      <div className={classNames(className, 'w-full bg-white ')} {...id}>
        {/* ---------------------------------- */}
        {showSoftLimits && !showHardLimits && (
          <SoftLimitBanner playwrightId={playwrightId} />
        )}
        {showHardLimits && <HardLimitBanner playwrightId={playwrightId} />}
        {/* ---------------------------------- */}
        <div
          className={classNames(
            'flex flex-col md:flex-row items-start px-3 sm:px-6 mx-auto w-full py-2 ',
            maxWidth
          )}
        >
          <h4
            className={classNames(
              'font-bold text-sm mb-2 min-w-[200px] sm:mb-0'
            )}
            {...setPlaywrightId('title')}
          >
            {t('organisation_usage')}
          </h4>
          <div
            className={classNames(
              'w-full mx-auto flex flex-row relative gap-x-4 sm:gap-x-20 justify-start'
            )}
          >
            <MIStat
              value={creditsRemaining > -1 ? creditsRemaining : 0}
              maxValue={credits?.total ?? 0}
              label="Org Credits Remaining"
              isRemaining
              showDenominator={false}
              playwrightId={setPlaywrightId('organisation-usage')}
            />
            {showPortfolioLimits && (
              <MIStat
                value={portfoliosRemaining > -1 ? portfoliosRemaining : 0}
                maxValue={portfolios?.total ?? 0}
                label="Portfolios Remaining"
                isRemaining
                playwrightId={setPlaywrightId('portfolios-usage')}
              />
            )}
            {showUserLimits && (
              <MIStat
                value={usersRemaining > -1 ? usersRemaining : 0}
                maxValue={users?.total ?? 0}
                label="Users Remaining"
                isRemaining
                playwrightId={setPlaywrightId('users-usage')}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );

  if (usageWrapper) {
    return <div className={classNames(usageWrapper)}>{content}</div>;
  } else {
    return content;
  }
};

const SoftLimitBanner: FC<{} & PlaywrightProps> = ({ playwrightId }) => {
  const [id, setPlaywrightId] = usePlaywright(playwrightId || '');
  const t = useTranslations();

  const [showBanner, setShowSoftLimits] = useSessionStorage(
    'showSoftLimitsBanner',
    true
  );

  if (!showBanner) return null;

  return (
    <div
      className={classNames(
        'py-2 w-full bg-highlight text-white text-xs text-center relative'
      )}
      {...setPlaywrightId('soft-limits')}
    >
      {t.rich('soft_limit_exceeded', {
        a: children => (
          <a
            className="underline"
            href={`mailto:${upgradeHref.toString()}`}
            target="_blank"
            rel="noreferrer"
          >
            {children}
          </a>
        ),
      })}
      <button
        className="absolute right-0 top-0 p-2"
        onClick={() => setShowSoftLimits(false)}
      >
        <Cross className="w-4 h-4 stroke-white" />
      </button>
    </div>
  );
};

const HardLimitBanner: FC<{} & PlaywrightProps> = ({ playwrightId }) => {
  const [id, setPlaywrightId] = usePlaywright(playwrightId || '');
  const t = useTranslations();
  return (
    <div
      className={classNames(
        'py-2 w-full bg-red-600 text-white text-xs text-center'
      )}
      {...setPlaywrightId('hard-limits')}
    >
      {t.rich('hard_limit_exceeded', {
        a: children => (
          <a
            className="underline"
            href={`mailto:${upgradeHref.toString()}`}
            target="_blank"
            rel="noreferrer"
          >
            {children}
          </a>
        ),
      })}
    </div>
  );
};

export default MIUsage;

// TODO - update this 403 message
// This  request on is not authorised. Please provide correct authentication.
