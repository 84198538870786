import React from 'react';

import {
  OrganisationFeature,
  OrganisationFeatureKeys,
  OrganisationFeaturesObject,
} from '../types/organisations';

interface useFeaturesProps {
  featuresArray: OrganisationFeature[];
}

const useFeatures = ({ featuresArray }: useFeaturesProps) => {
  const reducedFeatures = React.useMemo(() => {
    return (
      featuresArray?.reduce(
        (
          allFeatures: OrganisationFeaturesObject,
          currentFeature: OrganisationFeature
        ) => {
          allFeatures[currentFeature.name] = currentFeature;
          return allFeatures;
        },
        {} as OrganisationFeaturesObject
      ) || {}
    );
  }, [featuresArray]);

  const featureIsActive = (feature: OrganisationFeatureKeys) => {
    return reducedFeatures[feature]?.meta?.status === 'ACTIVE';
  };
  const featureIsUpSell = (feature: OrganisationFeatureKeys) => {
    return reducedFeatures[feature]?.meta?.status === 'UPSELL';
  };
  const featureIsHidden = (feature: OrganisationFeatureKeys) => {
    return (
      reducedFeatures[feature]?.meta?.status === 'HIDDEN' ||
      !reducedFeatures[feature]
    );
  };

  return {
    features: reducedFeatures,
    featureIsActive,
    featureIsUpSell,
    featureIsHidden,
  };
};

export default useFeatures;
