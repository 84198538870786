import { kebabCase } from 'lodash';

import {
  MakePlaywrightAttr,
  PlaywrightAttr,
  PlaywrightHook,
} from '../types/global';

export const toKebabCase = (str: string) =>
  str
    .split('.')
    .map(s => kebabCase(s))
    .join('.');

/**
 *
 * @param initialId - can be either a string or the inherited playwright id in object form
 * @param addDataId - add the same ID for data-testid used by React Testing Library
 */
export function usePlaywright(
  initialId?: string | PlaywrightAttr,
  addDataId = true
): PlaywrightHook {
  let id: string = '';

  if (typeof initialId === 'string') {
    id = initialId;
  } else {
    id = initialId?.['data-playwright-id'] ?? '';
  }

  let attr = {
    'data-playwright-id': toKebabCase(id),
    ...(addDataId && { 'data-testid': toKebabCase(id) }),
  };

  const setPlaywrightId: MakePlaywrightAttr = (newId, _addDataId = true) => {
    const splitInitialId = id.split('.');
    const splitNewId = newId.split('.');

    const _id = [...splitInitialId, ...splitNewId].join('.').toLowerCase();

    return {
      'data-playwright-id': toKebabCase(_id),
      ...((addDataId || _addDataId) && { 'data-testid': toKebabCase(_id) }),
    };
  };

  return [attr, setPlaywrightId];
}
