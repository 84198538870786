/* eslint-disable sonarjs/cognitive-complexity */

import * as Sentry from '@sentry/react';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import React from 'react';

import config from '../../config';
import useHubspotChat from '../../hooks/useHubspotChat';
import useOrganisation from '../../hooks/useOrganisation';
import { usePlaywright } from '../../hooks/usePlaywright';
import useUser from '../../hooks/useUser';
import NewDeploymentNotification from '../cards/NewDeploymentNotification';
import ErrorSkeleton from '../skeletons/ErrorSkeleton';
import SkeletonLayout from '../skeletons/SkeletonLayout';
import { ToastContainer } from '../toast/ToastContainer';
import MIUsage from './MIUsage';
import Nav from './Nav';
import Seo from './Seo';

interface LayoutProps {
  title?: string;
  pageTitle?: string;
  description?: string;
  noMenu?: boolean | undefined;
  noNav?: boolean | undefined;
  fullWidth?: boolean;
  noAuthRequired?: boolean;
  className?: string;
  children?: React.ReactNode;
  containerClassName?: string;
  adminRequired?: boolean;
  usageClassName?: string;
  /**
   * MIUsage is absolute positioned, so this wrapper helps with margin and spacing
   */
  usageWrapper?: string;
  showUsage?: boolean;
}

const Layout = ({
  noNav,
  title,
  description,
  pageTitle,
  fullWidth,
  noAuthRequired,
  className,
  children,
  containerClassName,
  adminRequired,
  usageClassName,
  usageWrapper,
  showUsage = true,
}: LayoutProps) => {
  const router = useRouter();
  const [playwrightId] = usePlaywright('layout');

  const path: string = router.asPath;

  // renamed for consistency
  const { data: session, status } = useSession();

  // trigger here to prevent flash of no org details on report
  useOrganisation({ fetch: !noAuthRequired });
  const { user, isAdmin, loading, error, isError, userHasNoAccessToPath } =
    useUser(!noAuthRequired);

  const { HubspotScript } = useHubspotChat('4623266', true, user);

  React.useEffect(() => {
    if (user) {
      // set sentry to identify user
      Sentry.setUser({ email: user.email || '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!loading && adminRequired && !isAdmin && user && status !== 'loading') {
    router.push('/no-access');
    return <SkeletonLayout />;
  }
  const noAccessToPage = userHasNoAccessToPath(path);

  if (!loading && user && status !== 'loading' && noAccessToPage) {
    router.push('/no-access');
    return <SkeletonLayout />;
  }

  if (!loading && !session && !noAuthRequired && status !== 'loading') {
    router.push('/login');
  }

  if (!noAuthRequired && loading) {
    return <SkeletonLayout noNav={noNav} />;
  }

  if (!noAuthRequired && isError)
    return (
      <ErrorSkeleton
        header={`${error?.message}`}
        code={error?.status}
        seo={{
          title,
          description,
          path,
        }}
      />
    );

  return (
    <div {...playwrightId}>
      {config.IS_PROD && <NewDeploymentNotification />}
      {config.IS_LIVE_PROD && <HubspotScript />}
      {user && !loading && status === 'authenticated' && <ToastContainer />}

      <Seo title={title} description={description} path={path} />
      <div className="h-screen bg-bg overflow-hidden flex ">
        {!noNav && user && <Nav path={path} isAdmin={isAdmin} />}

        <main
          className={`flex-1 relative overflow-y-auto focus:outline-none ${
            !noNav && !fullWidth && 'pt-12'
          } ${className}`}
          id="layout-container"
        >
          {showUsage && (
            <MIUsage className={usageClassName} usageWrapper={usageWrapper} />
          )}
          <div className={` ${!noNav && !fullWidth ? 'py-6' : ''} h-full`}>
            <div className="px-4 sm:px-6 md:px-0">
              {pageTitle && user && (
                <h1 className="text-2xl font-semibold text-gray-900">
                  {pageTitle}
                </h1>
              )}
            </div>

            <div
              className={`${
                !fullWidth ? 'px-3 sm:px-6 max-w-5xl mx-auto' : ''
              } ${containerClassName}`}
            >
              {(user || noAuthRequired) && children}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;
