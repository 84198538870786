/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import appState from '../lib/appState';
import { getCustomMetricPaths } from '../lib/funcs/custom-metrics';
import fetcher from '../lib/utils/fetcher';
import { OrganisationType, OrganisationUser } from '../types/organisations';
import useBilling from './useBilling';
import useFeatures from './useFeatures';
import useSWRWithToasts from './useSWRWithToasts';

export const useOrganisation = (
  {
    fetch = true,
    revalidateOnFocus = false,
  }: {
    fetch?: boolean;
    revalidateOnFocus?: boolean;
  } = { fetch: true, revalidateOnFocus: false }
) => {
  const { user } = useRecoilValue(appState);
  const setState = useSetRecoilState(appState);

  const orgId = user?.organisation_id || null;
  const isOrgRoleAdmin = user?.organisation_role === 'Admin';

  const [totalReports, setTotalReports] = useState<number>();
  const [fetchingReports, setFetchingReports] = useState(false);

  const mutateOrgReports = () => {
    /**
     * Fetch the total number of reports
     */
    setFetchingReports(true);

    fetcher('/api/reports/list', 'POST', {
      pagination: { limit: 0, offset: 0 },
      filter_by: [],
    })
      .then(res => {
        if (res.ok && res.data?.total) {
          setTotalReports(res.data.total);
        }
      })
      .finally(() => {
        setFetchingReports(false);
      });
  };

  useEffect(() => {
    if (fetch && isOrgRoleAdmin) {
      mutateOrgReports();
    }
  }, [fetch, isOrgRoleAdmin]);

  // BILLING
  const { billing, limits } = useBilling({
    orgId,
  });

  // billing is for new clients, but checking for billing?.plan
  // is to ensure that we don't show the billing page to legacy clients
  // where the billing plan is {}
  const canUserSeeUsage: boolean =
    Object.keys(billing || {}).length && Object.keys(limits || {}).length
      ? true
      : false;

  const {
    data: orgDetailsRequest,
    isValidating: orgDetailsRequestValidating,
    mutate: mutateOrg,
  } = useSWRWithToasts<OrganisationType>(
    fetch && orgId && `/api/organisation/${orgId}`,
    fetcher,
    {
      revalidateOnMount: true,
      revalidateOnFocus,
    }
  );

  const {
    data: orgTotalUsersRequest,
    isValidating: orgTotalUsersRequestValidating,
    mutate: mutateUsers,
  } = useSWRWithToasts<{
    users: OrganisationUser[];
    total: number;
  }>(
    fetch &&
      isOrgRoleAdmin &&
      orgId &&
      `/api/organisation/${orgId}/users?limit=1`,
    fetcher,
    {
      revalidateOnMount: true,
      revalidateOnFocus,
    }
  );

  const orgDetailsRequestLoading =
    !orgDetailsRequest && orgDetailsRequestValidating;
  const orgTotalUsersRequestLoading =
    !orgTotalUsersRequest && orgTotalUsersRequestValidating;

  const isLoading =
    orgDetailsRequestLoading || fetchingReports || orgTotalUsersRequestLoading;

  const organisation = {
    name: user?.organisation_name,
    id: user?.organisation_id,
    subscription_tier: user?.subscription_tier,
    totalUsers:
      (orgTotalUsersRequest && orgTotalUsersRequest?.data?.total) || 0,
    totalOrganisationReports: totalReports,
    ...(orgDetailsRequest && orgDetailsRequest?.data),
    // billing contains properties that may overwrite some of the above so not spreading it
    billing,
    limits,
  };

  const customRisks: Record<string, string> = getCustomMetricPaths({
    data: organisation?.custom_risks || [],
  });

  const { features, featureIsActive, featureIsHidden, featureIsUpSell } =
    useFeatures({
      featuresArray: organisation?.features || [],
    });

  React.useEffect(() => {
    if (orgDetailsRequest?.data?.id && !orgDetailsRequestLoading) {
      setState({ user, organisation: { ...organisation, features: features } });
    }
  }, [orgTotalUsersRequest, orgDetailsRequest]);

  return {
    organisation: organisation as OrganisationType,
    mutateOrg,
    features,
    subscriptionTier: organisation.subscription_tier,
    customRisks,
    mutateOrgReports,
    mutateUsers,
    featureIsActive,
    featureIsHidden,
    featureIsUpSell,
    loading: isLoading,
    error: orgDetailsRequest?.isError,
    message: orgDetailsRequest?.message,
    canUserSeeUsage,
    isLegacy: !organisation?.billing,
  };
};

export default useOrganisation;
