import { useHasNewDeploy } from 'next-deploy-notifications';
import React from 'react';
import config from '../../config';
import { useTranslations } from 'next-intl';

const NewDeploymentNotification = () => {
  let { hasNewDeploy } = useHasNewDeploy({
    endpoint: `${config.URL}/api/has-new-deploy`,
    interval: 1000 * 60 * 5
  });
  const t = useTranslations();

  return hasNewDeploy ? (
    <article className="fixed top-0 w-full text-center py-4 z-40  bg-gray-100 shadow-xl">
      {t('new_version_available')}
      <button
        className="text-highlight inline-block ml-4"
        onClick={() => window.location.reload()}
      >
        {t('load_latest_version')}
      </button>
    </article>
  ) : null;
};

export default NewDeploymentNotification;
