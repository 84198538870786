import classNames from 'classnames';

import { usePlaywright } from '../../hooks/usePlaywright';
import { isValueValid } from '../../lib/utils/mi-helperss';
import { PlaywrightProps } from '../../types/global';

export interface UsageStatsProps extends PlaywrightProps {
  label?: string;
  value: number;
  maxValue?: number;
  locale?: string;
  showDenominator?: boolean;
  isRemaining?: boolean;
}

export const MIStat: React.FC<UsageStatsProps> = ({
  label,
  value,
  maxValue,
  showDenominator = true,
  locale = 'en-GB',
  playwrightId,
  isRemaining,
}) => {
  const [id, setPlaywrightId] = usePlaywright(playwrightId || 'usage-stat');

  const getLoadingBarLength = () => {
    if (!isValueValid(value) || !isValueValid(maxValue)) return 0;
    const safeMaxValue = maxValue || 1;

    // credits
    // 1101 remaining out of 1000 total
    // 1101 / 1000 = 1.101
    // 1.101 * 100 = 110.1
    // 110.1% to be used

    // portfolios
    // 994 / 20 total
    // 994 / 20 = 49.7
    // 49.7 * 100 = 4970
    // 4970% has been used

    // if there is more than 100% usage or availability, return 100
    if (value > safeMaxValue) return 100;

    // if the value is less or equal than 0, return 0
    if (value <= 0) return 0;

    return (value / safeMaxValue) * 100;
  };

  const loadingBarLength = getLoadingBarLength();

  return (
    <div className={classNames('flex flex-col space-y-0.5')} {...id}>
      <div className="flex items-center justify-between text-xs gap-x-4 sm:gap-x-10">
        <p className={classNames('')} {...setPlaywrightId('label')}>
          {label}
        </p>
        <p className={classNames('font-bold')} {...setPlaywrightId('value')}>
          {Intl.NumberFormat(locale, {
            compactDisplay: 'short',
          }).format(value)}
          {isValueValid(maxValue) &&
            maxValue?.toString().length &&
            showDenominator && (
              <span>
                {' '}
                /{' '}
                {Intl.NumberFormat(locale, {
                  compactDisplay: 'short',
                }).format(maxValue)}
              </span>
            )}
        </p>
      </div>
      <LoadingBar
        isRemaining={isRemaining}
        percentage={loadingBarLength}
        playwrightId={setPlaywrightId('progress')}
      />
    </div>
  );
};

export const LoadingBar: React.FC<
  {
    percentage: number;
    isRemaining?: boolean;
  } & PlaywrightProps
> = ({ percentage, playwrightId, isRemaining }) => {
  const [, setPlaywrightId] = usePlaywright(playwrightId || 'loading-bar');
  return (
    <div
      className={classNames('relative bg-gray-100 h-1 w-full rounded-full')}
      {...setPlaywrightId('progress-container')}
    >
      <div
        className={classNames('absolute top-0 left-0 h-full', {
          'bg-new-highlight': percentage < 100 || isRemaining,
          'bg-red-500': percentage >= 100 && !isRemaining,
        })}
        {...setPlaywrightId('progress-value')}
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
};
