/* eslint-disable security/detect-object-injection */
/* eslint-disable no-console */
import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import { usePlaywright } from '../../hooks/usePlaywright';
import { ToastAction } from '../../hooks/useToast';
import { RichTranslation } from '../../types/global';

export type ToastType = 'success' | 'info' | 'error' | 'warning';

type ToastClassname = {
  root?: string;
  title?: string;
  description?: string;
};

export const ToastLayout: FC<{
  children?: ReactNode;
  title?: RichTranslation;
  description?: RichTranslation;
  className?: ToastClassname;
}> = ({ title, description, children, className }) => {
  const [playwrightId] = usePlaywright(`toast-layout`);
  return (
    <div
      className={classNames('flex flex-col items-start', className?.root)}
      {...playwrightId}
    >
      <h6
        className={classNames('font-bold text-black mb-0.5', className?.title)}
      >
        {title}
      </h6>
      <p
        className={classNames('text-xs leading-normal', className?.description)}
      >
        {description}
      </p>
      {children && <div className="flex gap-x-2 mt-2">{children}</div>}
    </div>
  );
};

export interface ToastBodyProps {
  title: RichTranslation;
  description?: RichTranslation;
  className?: ToastClassname;
  actions?: ToastAction[];
}

export const ToastBody: FC<ToastBodyProps> = ({
  title,
  description,
  actions,
  className,
}) => {
  return (
    <ToastLayout title={title} description={description} className={className}>
      {actions?.map((a, i) => (
        <button
          className={`py-0.5 text-sm ${
            a.type === 'dismiss' ? 'text-slate-400 ' : 'text-orange-300'
          }`}
          onClick={() => a.action()}
          key={`toast-btn-${i}`}
        >
          {a.label}
        </button>
      ))}
    </ToastLayout>
  );
};
