import { PlaywrightProps } from '../../types/global';

const Cross = ({
  className = 'h-6 w-6',
  playwrightId
}: { className?: string } & PlaywrightProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      stroke="#EB0909"
      data-testid="icon-cross"
      {...playwrightId}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
};

export default Cross;
