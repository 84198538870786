export const isValueValid = (val: unknown) => {
  if (
    val === undefined ||
    val === null ||
    typeof val !== 'number' ||
    isNaN(val)
  ) {
    return false;
  }
  return true;
};
